import React from "react";
import { PatientPage, SEO, NumeroBlock, Boxy } from "components";
import { Row, Col } from 'react-flexbox-grid';
import { DiscussionGuideCallout, AveedInformation } from "components/shared/callouts";
import imgSchedule from 'images/injection-schedule-chart.png';
import imgChair from 'images/guy-with-chair.png';

import './what-to-expect.scss';

const pageDescription = "Learn about patient savings for AVEED® (testosterone undecanoate) injection CIII. AVEED® (testosterone undecanoate) offers treatment assistance for those who qualify.";

const WhatToExpectPage = () => {
  return (
    <PatientPage pageClassName="what-to-expect">
      <SEO pageTitle="What to Expect?" pageDescription={pageDescription} />
      <div className="dfa-container gutter-left gutter-right">
        <Row>
          <Col xs={12}>
            <h1>What to Expect When You Receive AVEED<sup>&reg;</sup></h1>
            <p>AVEED<sup>&reg;</sup> is an injection that is always given at the doctor's office. AVEED<sup>&reg;</sup> is only available through your doctor's office, clinic, or hospital, and it is not a medicine you will give yourself at home.</p>
            <h2>How your prescription gets filled:</h2>
            <p>Your physician may either buy AVEED<sup>&reg;</sup> and bill your health plan or order through a Specialty Pharmacy. The Specialty Pharmacy will contact you to collect your payment, bill your health plan, if applicable, and deliver the medication to your doctor's office prior to your appointment.</p>
            <p>If your physician orders through a Specialty Pharmacy, you should expect to receive a phone call from your Specialty Pharmacy prior to each injection. </p>
            <h2>When you receive AVEED<sup>&reg;</sup>, here's what you should expect:</h2>


            <div className="colored-table">
              <NumeroBlock
                colorWare="green"
                numero="1"
                title=""
              >
                Your doctor will inject AVEED<sup>&reg;</sup> into the muscle of your buttock. Receiving the injection itself takes approximately 60 to 90 seconds.
                </NumeroBlock>
              <NumeroBlock
                colorWare="orange"
                numero="2"
                title=""
              >
                Remain at the doctor's office for 30 minutes in case you experience any serious injection-related side effects.
                </NumeroBlock>
              <NumeroBlock
                colorWare="gold"
                numero="3"
                title=""
              >
                Return to the doctor's office for regular injections. <strong>You will get an injection when you start, another injection 4 weeks later, and then an injection every 10 weeks.</strong>
              </NumeroBlock>
            </div>
            <p>
              Your doctor will test your blood before you receive and while you are receiving AVEED<sup>&reg;</sup>.</p>
            <p>AVEED<sup>&reg;</sup> can cause serious side effects including lung problems and allergic reactions. Serious side effects relating to the heart and blood vessels and mental status are associated with abuse of testosterone and anabolic androgenic steroids.</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <p className="h1">Why a 30-Minute Wait?</p>
            <p>Patients must remain at the doctor's office for 30 minutes following each injection with AVEED<sup>&reg;</sup>, because AVEED<sup>&reg;</sup> may cause serious side effects. These side effects may occur during or right after an injection.</p>
            <p>AVEED<sup>&reg;</sup> can cause a temporary but potentially serious condition called a pulmonary oil microembolism (POME). POME is <strong>NOT</strong> a blood clot. AVEED<sup>&reg;</sup> contains castor oil and POME is believed to be caused by tiny droplets of castor oil that have traveled to the lungs. Castor oil is one of the ingredients that enables AVEED<sup>&reg;</sup> to be injected every 10 weeks after the first month of therapy.</p>
            <p>AVEED<sup>&reg;</sup> may also cause a severe allergic reaction called anaphylaxis.</p>
            <p>Because of these serious potential risks, it's important that you talk to your doctor about whether the benefits of receiving AVEED<sup>&reg;</sup> outweigh these potential risks. Ask your doctor or nurse if you are unsure about the risks of AVEED<sup>&reg;</sup>.</p>
            <p className="strong">Among 3,556 patients in worldwide clinical trials*:</p>
            <ul className="green">
              <li><span>9 POME events occurred in 8 patients</span></li>
              <li><span>2 events of anaphylaxis occurred</span></li>
            </ul>
            <p className="footnote">*Patients treated with intramuscular testosterone undecanoate.</p>
          </Col>
          <Col xs={12} md={6}>
            <div style={{ display: 'flex', alignItems: 'flex-end', height: '100%' }}>
              <img src={imgChair} alt="Why a 30 minute wait?" className="expand" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <p className="h1">Be Sure to Keep up with Your AVEED<sup>&reg;</sup> Treatments as Prescribed </p>
            <p className="txt-first-month"><span className="strong">5 SHOTS A YEAR</span> AFTER THE FIRST MONTH OF THERAPY</p>
            <p>To keep your testosterone levels in the normal range, it's important to stay with the recommended dosing schedule and get your treatments <strong>every 10 weeks after the first month of therapy</strong>.</p>

            <Boxy title="Injection Schedule" className="injection-schedule-box">
              <img src={imgSchedule} alt="Injection Schedule" style={{ maxWidth: 600 }} className="expand" />
            </Boxy>
          </Col>
        </Row>

        <Row className="callout-blocks-container">
          <Col xs={12} md={6}>
            <AveedInformation />
          </Col>
          <Col xs={12} md={6}>
            <DiscussionGuideCallout />
          </Col>
        </Row>

      </div>
    </PatientPage>
  )
}

export default WhatToExpectPage